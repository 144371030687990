import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import ContactForm from '../components/ContactForm'
import Pagination from '../components/Pagination'
import decodeHtml from '../utilities/decodeHtml'

const Category = props => {
  const { data, pageContext } = props
  const { edges: posts, totalCount } = data.allWordpressPost
  const { title: siteTitle } = data.site.siteMetadata
  const { name: category } = pageContext
  const title = `${category} (${totalCount})`

  return (
    <Layout>
      <Helmet title={`${decodeHtml(category)} | ${siteTitle}`} />
      <div className="banner short">
      </div>
      <PostList posts={posts} title={title} />
      <Pagination pageContext={pageContext} pathPrefix="/" />
      <ContactForm/>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost(filter: { categories: {elemMatch: { slug: { eq: $slug } } } }) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
