import React from 'react'
import { Link } from 'gatsby'
import {
  FaChevronRight,
  FaChevronLeft,
} from 'react-icons/fa'

const Pagination = ({ pageContext, pathPrefix }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <nav className="container" role="navigation">
      <div className="navbar navbar-menu" style={{width: '100%'}}>
        {previousPagePath && (
          <div className="navbar-item">
            <Link to={previousPagePath} className="btn btn-primary" rel="prev">
              <FaChevronLeft/> Previous
            </Link>
          </div>
        )}
        {nextPagePath && (
          <div className="navbar-item">
            <Link to={nextPagePath} className="btn btn-primary" rel="next">
              Next <FaChevronRight/>
            </Link>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Pagination
